<template>
  <div
    v-loading="isLoading"
    class="container"
  >
    <div
      v-if="story"
      class="PressRelease StoryFull"
    >
      <div class="StoryFull__wrapper">
        <div class="StoryFull__content">
          <div class="StoryFull__content-inner">
            <div
              v-if="brandLogo"
              class="PressRelease__BrandLogo m-b-m"
            >
              <round-avatar
                :src="brandLogo"
                size="x-large"
                class="m-l-a"
              />
            </div>
            <div class="StoryFull__headlines-wrap">
              <div class="StoryFull__pressRelease">
                {{ story.prDate }}
              </div>
              <h1
                :class="getCustomStyleClassesFor('headline')"
                itemprop="headline"
                class="StoryFull__headline"
              >
                {{ story.headline }}
              </h1>

              <p
                v-if="story.subheadline"
                :class="getCustomStyleClassesFor('subheadline')"
                itemprop="alternativeHeadline"
                class="StoryFull__subheadline"
                v-html="story.subheadline"
              />
            </div>
            <div
              :class="getCustomStyleClassesFor('copytext')"
              itemprop="articleBody"
              class="StoryFull__copy-text content m-b-xl fr-view"
              v-html="story.copytext"
            />
            <div
              :class="getCustomStyleClassesFor('boilerplate')"
              class="StoryFull__boilerplate"
              v-html="story.boilerplate"
            />
            <v-separator darker />
            <div
              :class="getCustomStyleClassesFor('contact')"
              class="StoryFull__contact"
              v-html="story.contact"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _merge from 'lodash/merge'
import { Story } from '@hypefactors/shared/js/models/Story'
import { StoryApiService } from '@hypefactors/shared/js/services/api/StoryApiService'

/**
 * Generates the PressRelease view for sending a PR to a journalist
 * @module PressRelease
 */
export default {
  name: 'PressRelease',

  props: {
    storySlug: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isLoading: false,
      /** @type HF_Story */
      story: new Story()
    }
  },

  computed: {
    brandLogo () {
      return (this.story.brand && this.story.brand.logo) ? this.story.brand.logo.cropped : ''
    },
    mergedStyle () {
      return _merge(this.story.prStyles.styles, this.story.prStyles.overrides)
    }
  },

  mounted () {
    document.documentElement.classList.add('has-background-white')
    this.fetchStory()
  },

  methods: {
    async fetchStory () {
      const story = await StoryApiService.fetchStory(this.storySlug, {
        params: {
          include: ['brand', 'pr_styles']
        }
      })

      this.story = new Story(story)
    },

    getCustomStyleClassesFor (field) {
      return {
        [`has-text-${this.mergedStyle[field].fontSize}`]: this.mergedStyle[field].fontSize,
        [`has-font-${this.mergedStyle[field].fontFamily}`]: this.mergedStyle[field].fontFamily
      }
    }
  }
}
</script>

<style lang="scss">
@page {
  size: A4 portrait;
  margin: 1in; // default MS word - 1 inch
}

@media print {
  // remove the extra spacings for Print, we have margin on the page itself
  .page-pr_story {
    .container {
      margin: 0;
    }

    .PressRelease {
      padding: 0;
    }

    .app-content-wrapper {
      min-height: auto;
    }
  }
  // stop page from being cutoff
  .StoryFull__copy-text {
    h3 {
      page-break-inside: avoid;
      page-break-after: avoid;
    }

    p {
      page-break-inside: avoid;
    }
  }
}
</style>
